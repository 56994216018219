.navbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #333;
    border-bottom: 1px solid #ccc;
    width: 100%;
    position: fixed;
    top: 0;
    z-index: 1000;
  }
  
  .left-items {
    display: flex;
    align-items: center;
  }
  
  .logo-image {
    max-width: 150px;
    height: auto;
    margin-right: 20px;
    margin-left: 50px;
  }
  
  .navbar-nav {
    list-style: none;
    display: flex;
    align-items: center;
    gap: 20px;
  }
  
  .nav-item {
    margin-right: 70px;
  }
  
  .nav-link {
    color: white;
    text-decoration: none;
    font-size: 16px;
  }
  
  .nav-link:hover {
    text-decoration: underline;
  }
  
  /* Dropdown styles */
  .dropdown-menu {
    display: none;
    position: absolute;
    font-size: 16px;
    background-color: #333;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    list-style: none;
    padding: 10px;
    margin: 0;
  }
  
  .dropdown-item {
    padding: 10px;
    color: white;
    text-decoration: none;
    display: block;
    text-align: left;
  }
  
  .no-underline {
    text-decoration: none;
    color: inherit;
  }
  
  .no-underline:hover {
    text-decoration: none;
  }
  
  .dropdown-item:hover {
    background-color: #beb8b8;
  }
  
  .nav-item.dropdown:hover .dropdown-menu {
    display: block;
  }
  
  .social-icons {
    display: flex;
    align-items: center;
    margin: 0;
    padding: 0;
    font-size: 30px;
  }
  
  .social-icons a {    
    color: white;
    text-decoration: none;
    margin-right: 30px;
    font-size: 30px;
    /* margin-bottom: 10px; */
  }
  
  /* Mobile Menu */
  .menu-icon {
    display: none;
    flex-direction: column;
    cursor: pointer;
    padding: 10px;
  }
  
  .menu-icon .bar {
    background-color: white;
    height: 4px;
    width: 25px;
    margin: 4px 0;
  }
  
  .navbar-nav {
    display: flex;
  }
  
  .navbar-nav.active {
    display: block;
    position: absolute;
    top: 60px;
    right: 0;
    background-color: #333;
    width: 100%;
    padding: 20px;
  }
  
  .navbar-nav li {
    margin: 10px 0;
    text-align: center;
  }
  
  .navbar-nav li a {
    font-size: 16px;
  }
  
  /* Media Query for Mobile */
  @media (max-width: 768px) {
    .menu-icon {
      display: flex;
    }
  
    .navbar-nav {
      display: none;
      flex-direction: column;
      width: 100%;
      background-color: #333;
    }
  
    .navbar-nav.active {
      display: block;
    }
  
    .nav-item {
      margin-right: 0;
    }
  
    .nav-link {
      color: white;
      font-size: 16px;
      padding: 10px;
    }
  }
  