.App {
  text-align: center;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  max-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-size: 14px;
  color: white;
}

.background-image {
  width: 100%;
  object-fit: cover;
  padding-top: 3.2%;
  z-index: -1;
}
.background-image {
  width: 100%;
  height: calc(100vh); 
  object-fit: contain; 
  position: relative;
  z-index: -1;
  margin: 0 auto; 
  background-color: black;
  border: none;
}

.overlay-text {
  position: absolute;
  top: 65%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  font-size: 48px;
  font-weight: bold;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.6);
  text-align: center;
}


html, body, #root {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
}

.App {
  height: 100%;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
