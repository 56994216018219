/* Footer Styling */
.footer {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: #333;
    color: #fff;
    padding: 5px 15px;
    display: flex;
    justify-content: space-between; /* Spread the content to both edges */
    align-items: center; /* Ensure text and image are aligned on the same line */
    z-index: 1;
}

/* Disclaimer Section */
.disclaimer-section {
    display: flex;
    justify-content: space-between; /* Ensure space between text and image */
    width: 100%;
    max-width: 900px;
    align-items: center; /* Ensure vertical alignment of text and image */
}

/* Disclaimer Text */
.disclaimer {
    font-size: 12px;
    color: #ccc;
    font-style: italic;
    line-height: 1.2;
    margin: 0;
    text-align: left; /* Keep text to the left */
    max-width: 80%; /* Restrict text width */
}

/* Disclaimer Image */
.disclaimer-image {
    margin-left: auto; /* Push image to the right edge */
}

.disclaimer-image img {
    max-width: 90%; /* Adjust the image size */
    height: auto;
    border-radius: 8px;
}

/* Footer Bottom (Copyright) */
.footer-bottom {
    font-size: 12px;
    color: #ccc;
    text-align: center;
    margin-right: 10px;
    margin-bottom: 0;
    line-height: 1;
}

/* Responsive Design */
@media (max-width: 600px) {
    .footer {
        padding: 5px 10px;
    }

    .disclaimer {
        max-width: 60%; /* Adjust text width */
    }

    .disclaimer-image img {
        max-width: 25%; /* Adjust image size for smaller screens */
    }

    .footer-bottom {
        font-size: 12px;
    }
}
