.resources-page {
padding: 20px;
text-align: center;
}

.video-container {
display: flex;
flex-direction: column;
gap: 40px; /* Adds spacing between videos */
margin-top: 20px;
align-items: center;
margin-bottom: 20%;
}

.video-item {
text-align: center;
}

video {
width: 80%; /* Adjust the width of videos */
max-width: 800px;
border: 2px solid #333;
border-radius: 8px;
box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}
