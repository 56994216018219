.about-us {
padding: 2rem;
background-color: #f4f4f9;
font-family: Arial, sans-serif;
color: #333;
}

.container {
max-width: 800px;
/* margin: 0 auto; */
margin-top: 5%;
margin-left: 22%;
margin-bottom: 10%;
}

.title {
font-size: 1.5rem;
text-align: center;
color: #3c5d7e;
margin-bottom: 1rem;
}

p {
line-height: 1.6;
font-size: 1.1rem;
margin-bottom: 1rem;
}

.intro {
font-size: 1.2rem;
font-weight: bold;
}

.closing {
text-align: center;
font-size: 1.2rem;
margin-top: 2rem;
color: #3c5d7e;
}

h1{
    margin-top: 8%;
}